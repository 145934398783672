// Config
const minLoadingTimeBeforeShowing = 0
const minShowingTime = 50
const loadedBufferTime = 50
const loading = document.getElementById("page-loading")!

// State
let loadCount = 0
let shownAt = 0
// Callbacks
let showTimeout: number | null = null
let hideTimeout: number | null = null

// Loading Page manages the "loaded" class on the body tag, which is used to show the loading spinner
// We do not want to flash the user with a spinner if the page loads quickly
// So we only show the spinner if the page takes longer than minLoadingTimeBeforeShowing to load
// And we only hide the spinner if it has been shown for at least minShowingTIme
// We also want a buffer to prevent flickering when the spinner is shown and hidden quickly
export default function trackLoading(reason: string) {
  loadCount++
  console.log("trackLoading", loadCount, reason)
  if (showTimeout === null) {
    showTimeout = window.setTimeout(() => {

      showTimeout = null
      if (loadCount == 0) return
      if (shownAt !== 0) return
      loading.classList.remove("loaded")
      shownAt = Date.now()
    }, minLoadingTimeBeforeShowing)
  }
  return ()=>trackLoaded("unload " + reason)
}

function trackLoaded(reason:string) {
  loadCount--
  console.log("trackLoaded", loadCount, reason, shownAt, hideTimeout)
  window.setTimeout(() => {
    // We only care about the last load event
    if (loadCount != 0) return
    // If this is not set, we have not shown the spinner yet
    if (shownAt === 0) return
    // If we have already set a hideTimeout, we should not set another one
    if (hideTimeout) return
    // If we have triggered a showTimeout, we should cancel it
    if (showTimeout) {
      clearTimeout(showTimeout)
      showTimeout = null
    }
    // Set a single timeout to hide the spinner after minShowingTime, only if loading has not started again
    const showUntil = shownAt + minShowingTime
    hideTimeout = window.setTimeout(() => {
      hideTimeout = null
      // If loading has started again, we should not hide the spinner
      if (loadCount !== 0) return
      // Hide the spinner and reset the shownAt time
      loading.classList.add("loaded")
      shownAt = 0
    }, Math.max(0, showUntil - Date.now()))
  }, loadedBufferTime)
}

if (window.trackLoading === undefined) {
  window.trackLoading = trackLoading
  window.trackLoaded = trackLoaded
  if (!window.trackLoadedAlready) {
    trackLoaded("initial")
  }
}


